export default [
  {
    text: "Actions",
    value: "actions",
    width: "60px",
    sortable: false,
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Name",
    value: "firstName",
    width: "250px",
    sortable: true,
    order: 1,
    hidable: false,
    hidden: false,
  },
  { text: "Roles", value: "roles", sortable: true, order: 2, hidable: true, hidden: false },
  { text: "Status", value: "isSuspended", sortable: true, order: 3, hidable: true, hidden: false },
  { text: "Email", value: "email", sortable: false, order: 4, hidable: true, hidden: false },
  {
    text: "Phone",
    value: "phoneNumber",
    sortable: false,
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Join Date",
    value: "joinDate",
    width: "120px",
    sortable: true,
    order: 6,
    hidable: true,
    hidden: false,
  },
];
